import Row from "react-bootstrap/Row";

function Dates() {
    return (
        <Row id="dates">
            <h2>Termine</h2>
            <h3>Derzeit keine Termine.</h3>
        </Row>
    )
}
export default Dates;
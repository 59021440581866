import Row from 'react-bootstrap/Row';

function Images() {
    return (
        <Row id="images">
            <h2>Bilder</h2>
            <h3>Derzeit keine Bilder</h3>
        </Row>
    )
}
export default Images;
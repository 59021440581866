import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

function Header() {
    return (
        <Row id="header">
            <h1>Schützenkompanie Steinach</h1>
            <img src="/kompanie.jpg"/>
        </Row>
    )
}

export default Header;
import Row from 'react-bootstrap/Row';

function Footer() {
    return (
            <Row className="footer">
                <h4>Impressum</h4>
                <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
                    Offenlegungspflicht laut §25 Mediengesetz.</p>
                <p>Schützenkompanie Steinach</p>
                <p>Bahnhofstraße 156,</p>
                <p>6150 Steinach,</p>
                <p>Österreich</p>
                <p>Organschaftliche Vertreter</p>
                <p>Obmann/Obfrau: Gerhard Schießendobler</p>
                <p>Obmann/Obfrau Stellvertreter: Florian Reimeir</p>

                <p>Vereinszweck:</p>
                <p>Schützenverein</p>
            </Row>
    )
}

export default Footer;
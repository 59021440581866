import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";

function About() {
    return (
        <Row id="about">
            <h2>Die Kompanie</h2>
            <p>Unsere Kompanie besteht seit vielen Jahren, und zählt über 70 aktive Mitglieder. Genau historische
                Aufzeichnung der Schützen Steinach gibt es aber leider nicht.</p>
            <p>Im Juni 2019 bei der Jahreshauptversammlung wurde ein neuer Vorstand für die Schützenkompanie Steinach am
                Brenner gewählt.</p>
            <img src="/ausschuss.jpg"/>
            <Col xs lg="6" className="fw-bold">
                <p><bold>Hauptmann</bold></p>
                <p><bold>Oberleutnant</bold></p>
                <p><bold>Leutnant</bold></p>
                <p><bold>Fähnrich</bold></p>
                <p><bold>Obmann</bold></p>
                <p><bold>Obmann Stellvertreter</bold></p>
                <p><bold>Kassier</bold></p>
                <p><bold>Schriftführer</bold></p>
                <p><bold>Waffenmeister</bold></p>
                <p><bold>Markedenterinvertreterin</bold></p>
                <p><bold>Jungschützenbetreuer</bold></p>
            </Col>
            <Col xs lg="6">
                <p>Josef Schwaninger</p>
                <p>Karl-Heinz Riedl</p>
                <p>Peter Scherl, Josef Spörr, Matthias Riedl, Andreas Massani</p>
                <p>Martin Rottensteiner</p>
                <p>Gerhard Schießendobler</p>
                <p>Florian Reimeir</p>
                <p>Peter Holzmann</p>
                <p>Matthias Hörtnagl</p>
                <p>Florian Reimeir</p>
                <p>Nadja Triendl</p>
                <p>Andreas Massani</p>
            </Col>
        </Row>
    )
}

export default About;
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";

function Contact() {
    return (
        <Row className="justify-content-md-center" id="contact">
            <Col xs lg ="8">
                <h2>Kontakt</h2>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Mas Mustermann"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>E-Mail Adresse</Form.Label>
                        <Form.Control type="email" placeholder="max.mustermann@email.at"/>
                        <Form.Text className="text-muted">
                            Wir brauchen deine E-Mail Adresse um dir antworten zu können.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Nachricht</Form.Label>
                        <Form.Control type="text" placeholder="Nachricht ..."/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Absenden
                    </Button>
                </Form>
            </Col>
        </Row>
    )

}

export default Contact;
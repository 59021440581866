import Container from "react-bootstrap/Container";
import './App.css';
import Navbar from "./components/Navbar";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Images from "./components/Images";
import Dates from "./components/Dates";
import About from "./components/About";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App" id="home">
        <Container>
            <Navbar/>
            <Header/>
            <About/>
            <Dates/>
            <Images/>
            <Contact/>
            <Footer/>
        </Container>

    </div>
  );
}

export default App;

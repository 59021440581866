import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import {Col} from "react-bootstrap";
function Navbar() {
    return (
        <Row>
            <Col>
                <img src="/logo.jpeg" height="80px" width="150px"/>
            </Col>

            <Nav
                activeKey="/home"
                //onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                className="justify-content-center"
            >
                <Nav.Item>
                    <Nav.Link href="#about">Die Kompanie</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#dates" >Termine</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#images">Bilder</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#contact">Kontakt</Nav.Link>
                </Nav.Item>
            </Nav>
        </Row>
    )

}
export default Navbar;